import { Expose } from 'class-transformer'
import { BaseModel } from './baseModel'

export class Media extends BaseModel {
  id!: number
  category!: string
  name!: string

  @Expose({ name: 'mime_type' })
  mimeType!: string

  @Expose({ name: 'thumb_url' })
  thumbUrl!: string

  lr!: string
  hr!: string
  url!: string
}
