/* eslint-disable camelcase */
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications'
import { useAuth } from '/-/plugins/auth'
import { useApplication } from '/~/state/application'
import { useRouter } from '/~/state/route'
import { Api } from '/-/plugins/api'
import { useError } from '/~/plugins/error'
import { Browser } from '@capacitor/browser'

export enum PushActionType {
  NewMessage = 'new_message',
  NewMeetingRequest = 'new_meeting_request',
  MeetingConfirmed = 'meeting_confirmed',
  MeetingCanceled = 'meeting_canceled',
  MeetingRescheduled = 'meeting_rescheduled',
  MeetingReminderV2 = 'meeting_reminder_v2',
  NewMeetingRequestV2 = 'new_meeting_request_v2',
  MeetingConfirmedV2 = 'meeting_confirmed_v2',
  MeetingCanceledV2 = 'meeting_canceled_v2',
  MeetingRescheduledV2 = 'meeting_rescheduled_v2',
  HomeworkUpdated = 'homework_status_updated',
  CustomPush = 'custom_push',
}

interface PushDataInterface {
  event_id?: number
  action?: PushActionType
  entity_id?: string | number
  path?: string
  url?: string
}

const WNHUB_URL = 'https://wnhub.io'
const WNHUB_EVENTS_URL = 'https://wnhub.io/events'

// we rely on this only when app on background
let appToken: string | null = null

export const usePushService = () => {
  const offset = new Date().getTimezoneOffset() / 60
  const resource = 'gateway-push'
  const { platform, isNative } = useApplication()
  const { processError } = useError()

  const registerNotifications = async () => {
    if (!isNative) {
      return
    }
    console.log('register notifications')
    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive !== 'granted') {
      return
    }

    await PushNotifications.register()
  }

  const addListeners = () => {
    PushNotifications.addListener('registration', async (token: Token) => {
      await registerAppToken(token.value)
    })
    PushNotifications.addListener('registrationError', (error: any) => {
      processError(error, false)
    })

    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async (notification: ActionPerformed) => {
        await providePushData(notification.notification.data)
      }
    )
  }

  const getRouteByPushData = (event: string | number, entity?: string | number, action?: PushActionType) => {
    if (!event) {
      console.error('native: incorrect route push data')
      processError(new Error(`native: incorrect route push data, action: ${action}, event: ${event}, entity: ${entity}`), false)
    }

    const defaultPath = `/events/${event}`

    switch (action) {
    case PushActionType.NewMessage:
      return `${defaultPath}/chats/${entity}`
    case PushActionType.HomeworkUpdated:
      return `${defaultPath}/academy/lesson/${entity}`
    case PushActionType.NewMeetingRequest:
      return { name: 'event-meeting-view', params: { eventSlug: event, id: entity, isInvite: 'true' }}
    case PushActionType.MeetingConfirmed:
    case PushActionType.MeetingCanceled:
    case PushActionType.MeetingRescheduled:
      return `${defaultPath}/meetings/list/${entity}`
    case PushActionType.NewMeetingRequestV2:
    case PushActionType.MeetingConfirmedV2:
    case PushActionType.MeetingCanceledV2:
    case PushActionType.MeetingRescheduledV2:
    case PushActionType.MeetingReminderV2:
      return `${defaultPath}/calendar/${entity}`
    default:
      return defaultPath
    }
  }

  const providePushData = async (data: PushDataInterface) => {
    const router = useRouter()
    const { event_id: eventId, action, entity_id: entityId, path, url } = data

    if (url) {
      if (url.startsWith(WNHUB_EVENTS_URL)) {
        window.open(
          `/eventhub/index.html?redirect=${url.split(WNHUB_URL)[1]}`,
          '_self'
        )
        return
      }

      if (url.startsWith(WNHUB_URL)) {
        window.open(`${url.split(WNHUB_URL)[1]}`, '_self')
        return
      }

      Browser.open({ url })
    } else if (action === PushActionType.CustomPush) {
      router.replaceWithinEvent({ name: 'notifications' })
      return
    }

    if (path && router) {
      if (['expo', 'lectures'].some(fragment => path.includes(fragment))) {
        router.replace({ path, query: { chat: 'open' }})
        return
      }
      router.replace({ path })
      return
    }

    if (eventId && action && entityId) {
      router.push(getRouteByPushData(eventId, entityId, action))
    }
  }

  const registerAppToken = async (token: string) => {
    const authStore = useAuth()

    if (!authStore.auth) {
      return
    }

    try {
      await Api.fetch({
        url: '/user',
        resource,
        method: 'POST',
        body: {
          platform,
          token,
          timezone: offset,
        },
      })
      appToken = token
    } catch (error: any) {
      processError(error, false)
    }
  }

  const removeAppToken = async () => {
    if (!isNative || !appToken) {
      return
    }

    try {
      await Api.fetch({
        url: '/user',
        resource,
        method: 'DELETE',
        body: {
          token: appToken,
        },
      })
      appToken = null
    } catch (error: any) {
      processError(error, false)
    }
  }

  return {
    registerNotifications,
    getRouteByPushData,
    addListeners,
    registerAppToken,
    removeAppToken,
  }
}
