export default {
  lectures: '直播演讲',
  lectures_archive: '演讲回放',
  program: '演讲安排',
  chats: '聊天',
  showcase: '开发者展示',
  showcase_top: 'Selected Games by WN',
  members: '参会者',
  companies: '公司介绍',
  consultants: '顾问',
  vacancies: '职位',
  meetings: '会议',
  speakers: '发言人',
  group_call: '群组通话',
  zoom_call: 'Zoom Call',
  wnmeet: 'WN Meet',
  agenda: '时间表',
  contacts: '联系人',
  events: '活动',
  publishers: '发行商展区',
  expo: '会场',
  media: '媒体合作伙伴',
  host: '主持自己的活动',
  our_media: '自媒体',
  partyspace: '派对区域',
  news: '新闻消息',
  volunteer_badges: 'Print Badges',
  home: 'Home',
  favorites: 'Favorites',
  academy_admin: 'Admin panel',
  academy_admin_home: 'Back to course',
  academy_admin_modules: 'Modules & Lessons',
  academy_admin_homeworks: 'Homeworks',
  academy_admin_promo_messages: 'Promo messages',
  academy_admin_lectures: 'Lectures archive',
  academy_admin_students: 'Students',
  academy_program: 'Course program',
  academy_stream_records: 'Stream records',
  academy_admin_help: 'Help',
  notifications: 'Notifications',
}
