export default {
  title: 'Login',
  forgot: 'Forgot Password?',
  sign_in: 'Sign in',
  remember: 'Remember me',
  create: 'Create an account',
  create_question: 'Don\'t have an account?',
  back_to_login: 'Back to login page',
  reset: 'Reset',
  recovery_title: 'Recovery password',
  recovery_info: 'Enter your user account\'s verified email address and we will send you a password reset link.',
  reset_title: 'Create your password',
  reset_info: 'Enter your new password.',
  reset_success_title: 'Check your inbox!',
  reset_success_text: 'Email with instructions for resetting your password has been sent to',
  passwords_not_match: 'The specified passwords must be identical',
  login_by_google: 'Sign in with Google',
  link_send_title: 'Magic Done',
  link_send_text: 'We sent an email to you at :email. It has a magic link that\'ll sign you in.',
  type_password: 'Type Password',
  send_magic_link: 'Send Magic Link',
  magic_code: 'Magic Code',
  register: 'Send link',
  register_title: 'Don’t have an account?',
  register_info: 'No worries. Enter your email below, and we will send you the magic link to access the platform. ',
  register_success_title: 'Check your inbox!',
  register_success_text: 'Email with instructions for login has been sent to',
  enter_magic_code: 'Enter the 4-digital code that we have emailed you to finish signing in:',
  email_not_found: 'There is no user with this email address',
  register_button: 'Register'
}
