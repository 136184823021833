export default {
  title: 'Логин',
  forgot: 'Забыли пароль?',
  sign_in: 'Войти',
  remember: 'Запомнить устройство',
  create: 'Создать аккаунт',
  create_question: 'У вас еще нет аккаунта?',
  back_to_login: 'Вернуться к авторизации',
  reset: 'Восстановить',
  recovery_title: 'Восстановление пароля',
  recovery_info: 'Введите подтвержденный адрес электронной почты вашей учетной записи, и мы отправим вам ссылку для сброса пароля.',
  reset_title: 'Придумайте свой пароль',
  reset_info: 'Придумайте и введите новый пароль.',
  reset_success_title: 'Проверьте почту!',
  reset_success_text: 'Письмо с инструкциями по сбросу пароля было отправлено на ваш адрес',
  passwords_not_match: 'Указанные пароли должны быть одинаковыми',
  login_by_google: 'Войти через Google',
  link_send_title: 'Магия готова',
  link_send_text: 'Мы отправили вам письмо на адрес :email с волшебной ссылкой, по которой вы войдете в систему.',
  type_password: 'Ввести пароль',
  send_magic_link: 'Отправить Magic Link',
  magic_code: 'Magic Code',
  register: 'Отправить ссылку',
  register_title: 'Нет аккаунта в WN Hub? ',
  register_info: 'Введите ваш email в окне ниже. На этот адрес мы отправим письмо с magic link для доступа на платформу.',
  register_success_title: 'Проверьте почту!',
  register_success_text: 'Письмо с инструкциями по входу было отправлено на ваш адрес',
  enter_magic_code: 'Введите 4-значный код, который мы отправили на ваш email, чтобы завершить авторизацию:',
  email_not_found: 'Пользователь с таким email не найден',
  register_button: 'Зарегистрироваться'
}
