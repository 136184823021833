<template>
  <div ref="editorEl"
       class="description_editor" />
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
})

const emit = defineEmits(['update:modelValue'])
const editorEl = ref<HTMLElement | null>(null)

onMounted(async () => {
  if (!editorEl.value) {
    return
  }
  const { default: Editor } = await import('@toast-ui/editor')

  await import('@toast-ui/editor/dist/toastui-editor.css')
  const editor = new Editor({
    el: editorEl.value,
    height: '400px',
    previewStyle: 'tab',
    initialEditType: 'wysiwyg',
    initialValue: props.modelValue || '',
    toolbarItems: [
      ['heading', 'bold', 'italic'],
      ['hr', 'quote'],
      ['ul', 'ol', 'indent', 'outdent'],
      ['link'],
    ],
    autofocus: false,
    events: {
      change: () => {
        emit('update:modelValue', editor.getMarkdown().replace(/<br>/g, '⠀'))
      },
    },
  })

  watch(() => props.modelValue, () => {
    if (props.modelValue !== editor.getMarkdown().replace(/<br>/g, '⠀')) {
      editor.setMarkdown(props.modelValue)
    }
  })
})
</script>

<style scope lang="scss">
.description_editor {
  width: 340px;
  h1,
  h2 {
    border-bottom: 0 !important;
  }

  // we have to write sizes and use important because vue-json-schema-form is trying to set their own styles (14px for all texts)
  h1,
  h2,
  h3,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin: 0 !important;
  }

  h1 {
    font-size: 1.875rem !important;
    line-height: 2.25rem !important;
  }

  h2 {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
  }

  h3 {
    font-size: 1.25rem !important;
    line-height: 1.75rem !important;
  }

  h4 {
    font-size: 1.125rem !important;
    line-height: 1.75rem !important;
  }

  h5 {
    font-size: 1rem !important;
    line-height: 1.5rem !important;
  }

  h6 {
    font-size: 0.875rem !important;
    line-height: 1.25rem !important;
  }
}
</style>
