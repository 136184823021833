<template>
  <main class="h-full overflow-hidden flex flex-col gap-10 bg-white justify-center items-center text-center px-4">
    <div
      class="h-full max-h-56 md:max-h-72 max-w-none object-contain text-primary-600"
      v-html="images['network']" />
    <div class="flex flex-col gap-4">
      <h1 class="text-2xl md:text-3xl font-black">
        {{ getLocal('errors.no_network_title') }}
      </h1>
      <p class="text-gray-500">
        {{ getLocal('errors.no_network_text') }}
      </p>
    </div>
    <BaseButton @click="update">
      {{ getLocal('errors.no_network_button') }}
    </BaseButton>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useLocale } from '/-/plugins/locale'
import BaseButton from '/-/components/button/base-button.vue'
import images from '/~/layouts/components/errors/error-images'

export default defineComponent({
  name: 'NetworkError',
  components: {
    BaseButton,
  },
  setup() {
    const { getLocal } = useLocale()

    return {
      getLocal,
      images
    }
  },
  methods: {
    update() {
      this.$router.replace(this.$router.currentRoute.value.path)
    }
  }
})
</script>
