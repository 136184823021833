export default {
  enter_magic_code: '输入我们通过邮件发送给您的 4 位验证码以完成登录：',
  register_success_text: '包含登录说明的邮件已发送至',
  register_success_title: '查看您的邮箱！',
  register_info: '请放心。在下方输入您的邮件，我们将向您发送访问平台的魔法链接。',
  register_title: '没有帐户？',
  register: '发送链接',
  magic_code: '魔法密码',
  get_a_ticket: '记住密码',
  title: '登录',
  forgot: '忘记密码了？',
  sign_in: '注册',
  remember: '记住密码',
  create: '创建帐户',
  create_question: '还没有账户？',
  back_to_login: '返回登录',
  reset: '重置',
  recovery_title: '找回密码',
  recovery_info: '输入您的账号注册邮箱，我们将向您发送密码重置链接。',
  reset_title: '创建您的密码',
  reset_info: '输入您的新密码。',
  reset_success_title: '请查看您的收件箱!',
  reset_success_text: '重置密码的说明已发送至',
  passwords_not_match: '设置的密码必须相同',
  login_by_google: '用谷歌账户登录',
  link_send_title: '魔法已完成',
  link_send_text: '我们通过 :email 向您发送了一封电子邮件。 请点击邮件内的魔法链接进行登录。',
  type_password: '输入密码',
  send_magic_link: '发送魔法链接',
  email_not_found: '没有使用此邮箱的用户',
  register_button: 'Register'
}
