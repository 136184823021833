export default {
  upgrade_required_text_2_3: '如有疑问请联系我们。',
  room_full_title: '该房间已满',
  forbidden_text_1: 'You don’t have access to this event',
  forbidden_text_2: 'To access the platform you need a ticket.',
  forbidden_text_3: 'Log in with a different account or get a ticket.',
  log_in: 'Log in',
  not_found_title: '糟糕……404!',
  not_found_text: '找不到您请求的页面。',
  main_page: '返回展览会',
  error_title: '服务器错误',
  forbidden_title: '禁止',
  forbidden_text: '注册就能开始使用平台。',
  room_full_text: '请稍后再尝试加入',
  change_account: '登录其他账户',
  link_error_title: '链接已过期',
  link_error_text: '该魔法链接已过期',
  upgrade_required_title: '需要升级',
  upgrade_required_text_1: '现阶段已没有可供服务商使用的免费门票。',
  upgrade_required_text_2: '如果您想加入 :name，请购买商务票。',
  upgrade_required_text_2_1: '如果您喜欢这一功能，请在这里点击升级门票。',
  upgrade_required_text_2_2: '如果你也想加入直播，请在这里点击升级门票。',
  upgrade_required_text_3: '升级后需要刷新页面!',
  upgrade_required_button: '升级',
  no_network_title: '无网络连接',
  no_network_text: '请查看您的网络连接，再刷新页面。',
  no_network_button: '重试',
  vote_failed: '投票失败',
  no_company_title: '公司必填',
  no_company_body: '如果你想添加一个游戏，请在个人简介上添加公司。',
  no_company_tip: '在添加公司后谨记刷新网页',
  forbidden_route_title: 'This section is not available for your ticket type',
  forbidden_route_text: 'You can upgrade your ticket',
  forbidden_route_button: 'Upgrade ticket',
  unknown_error_title: 'Unknown error',
  unknown_error_text: 'Developers will be notified about this event.',
}
