export default {
  new_message: '新消息来自',
  new_meeting_request: '新会议邀请',
  from: '发件人',
  date: '日期',
  new_date: '新日期',
  with: '与',
  declined: '已拒绝',
  rescheduled: '重新安排',
  accepted_meeting: '已接受会议',
  vote_accepted: '投票已接受',
  vote_accepted_descripton: '您对 :game 的投票已提交成功',
  info_updated_success: '信息更新成功',
  info_updated_error: '抱歉，信息未更新',
  lecture_agree_error: '抱歉，未能加入講座',

  calendar_lock_success: '插槽已成功鎖定',
  calendar_lock_error: '抱歉，插槽未鎖定',
  calendar_unlock_success: '插槽已成功解鎖',
  calendar_unlock_error: '抱歉，插槽未解鎖',

  vacancies_respond_success: '您的回复已成功發送',
  vacancies_respond_error: '抱歉，您的回复未發送',

  game_create_success: '遊戲創建成功',
  game_create_error: '抱歉，您的遊戲尚未創建',
  game_create_upload_media_error: 'The game was created, but there was an error loading the images',
  game_update_success: '遊戲更新成功',
  game_update_error: '抱歉，遊戲沒有更新',
  game_update_upload_media_error: 'The game was updated, but there was an error loading the images',
  game_delete_success: '遊戲已成功移除',
  game_delete_error: '抱歉，遊戲未刪除',

  meeting_request_success: '您的申请已经提交。等待对方确定会议',
  meeting_request_error: '抱歉，您的请求尚未发送',
  meeting_reschedule_success: '會議已成功改期',
  meeting_reschedule_error: '抱歉，會議沒有改期',
  meeting_accept_success: '會議被成功接受',
  meeting_accept_error: '抱歉，會議未被接受',
  meeting_decline_success: '會議被成功拒絕',
  meeting_decline_error: '抱歉，會議未被拒絕',
  meeting_request_datetime_error: 'Sorry, the date or time is incorrect. Your data may be outdated, try to reload the page',
  meeting_not_onsite_member: 'Sorry, this user doesn\'t participate on-site, but you can contact him in the chat.',

  chat_report_success: '举报已成功发送',
  chat_report_error: '抱歉，举报尚未发送',
  chat_report_already_sent: '抱歉，您已经提交了举报',
  chat_user_blocked_error: '抱歉，您无法发送消息，因为您因可疑活动而被暂时阻止',

  company_create_error: 'Sorry, failed to create new company',

  recording_start_error: 'Sorry, failed to start recording',
  recording_stop_error: 'Sorry, failed to stop recording',
  recording_stop_success: 'The record is saved! You can find it in the "Stream records" section of the admin panel.',

  id_copied_success: 'Id was successfully copied to clipboard',
  url_copied_success: 'The url was successfully copied to the clipboard',
}
