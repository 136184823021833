import { Type, Expose } from 'class-transformer'
import { useProfile } from '/~/state/profile'
import dayjs from 'dayjs'
import { formatLinks } from '/-/plugins/format'
import { parseFieldLocales, getFieldLocale } from '/~/plugins/utils'
import { useLocale } from '/-/plugins/locale'
import { PushActionType } from '/~/plugins/push-service'

export enum ChannelType {
  Private = 'private',
  Public = 'public',
  Notifications = 'notifications',
}
export class ChatFile {
  key!: string
  name!: string
}
export class ChatUnreadData {
  @Expose({ name: 'unread_messages' })
  unreadMessages = 0

  @Expose({ name: 'entity_types' })
  entityTypes!: { [key: string]: number }
}
export class ChatUser {
  @Expose({ name: 'icon_url' })
  iconUrl!: string

  id!: number
  name!: string

  @Expose({ name: 'is_subscription_active' })
  isSubscriptionActive!: boolean
}
export class ChatChannel {
  uuid!: string
  access!: ChannelType

  @Expose({ name: 'created_at' })
  createdAt!: string

  @Expose({ name: 'updated_at' })
  updatedAt!: string

  @Expose({ name: 'icon_url' })
  iconUrl!: null | string

  name!: null | string

  @Expose({ name: 'relate_id' })
  relatedId!: null | number

  @Expose({ name: 'related_type' })
  relatedType!: null | string

  @Expose({ name: 'entity_id' })
  entityId!: null | number

  @Expose({ name: 'entity_type' })
  entityType!: null | string

  @Expose({ name: 'unread_messages' })
  unreadMessages!: number

  @Expose({ name: 'last_message' })
  @Type(() => ChatMessage)
  lastMessage!: ChatMessage

  @Type(() => ChatUser)
  users!: ChatUser[]

  get isPrivate() {
    return this.access === ChannelType.Private
  }

  get partner() {
    const { profile } = useProfile()

    return this.users.find(user => user.id !== profile.value?.id)
  }

  get isCompany() {
    return this.entityId && this.entityType?.includes('company')
  }

  get isPromo() {
    return this.entityId && this.entityType === 'promo_company'
  }

  get isLesson() {
    return this.entityId && this.entityType === 'lesson'
  }

  get partnerId() {
    return this.partner?.id
  }

  get partnerName() {
    return this.partner?.name || ''
  }

  get partnerAvatar() {
    return this.partner?.iconUrl || ''
  }
}

export enum MessageCustomType {
  Question = 'question',
  Important = 'important',
  Call = 'call',
  Homework = 'homework',
  HomeworkApproved = 'homework_approved',
  HomeworkDeclined = 'homework_declined',
}
export class ChatMessage {
  @Expose({ name: 'channel_id' })
  channelId!: string

  @Expose({ name: 'is_visible' })
  isVisible!: boolean

  @Expose({ name: 'created_at' })
  createdAt!: string

  @Expose({ name: 'updated_at' })
  updatedAt!: string

  @Expose({ name: 'custom_data' })
  customData!: string

  @Expose({ name: 'reply_message' })
  @Type(() => ChatMessage)
  replyMessage?: ChatMessage

  @Expose({ name: 'reply_message_id' })
  replyMessageId!: string

  files!: string[]

  @Type(() => ChatFile)
  attachments!: ChatFile[]

  @Type(() => ChatUser)
  sender!: ChatUser

  text!: string

  uuid!: string

  @Expose({ name: 'custom_type' })
  customType!: MessageCustomType

  @Expose({ name: 'channel_access' })
  channelAccess!: ChannelType

  @Expose({ name: 'channel_entity_id' })
  channelEntityId!: null | number

  @Expose({ name: 'channel_entity_type' })
  channelEntityType!: null | string

  @Expose({ name: 'read_by' })
  @Type(() => ChatUser)
  readBy!: ChatUser[] | undefined

  get isReadBySomeone() {
    const { profile } = useProfile()

    return (this.readBy || []).some(user => user.id !== profile.value?.id)
  }

  get isHomework() {
    return this.customType === MessageCustomType.Homework
  }

  get isDeclinedHomework() {
    return this.customType === MessageCustomType.HomeworkDeclined
  }

  get isApprovedHomework() {
    return this.customType === MessageCustomType.HomeworkApproved
  }

  get isCompany() {
    return this.channelEntityId && this.channelEntityType?.includes('company')
  }

  get isPrivate() {
    return this.channelAccess === ChannelType.Private
  }

  get isPublic() {
    return this.channelAccess === ChannelType.Public
  }

  get shortTime() {
    return this.createdAt ? dayjs(this.createdAt).format('HH:mm') : ''
  }

  get shortDate() {
    return this.createdAt ? dayjs(this.createdAt).format('DD.MM.YYYY') : ''
  }

  get isMeAuthor() {
    const { profile } = useProfile()

    return this.sender.id === profile.value?.id
  }

  get messageFormatted(): string {
    return this.text
      .trim()
      .replace(/[\r\n]+/g, '\n')
  }

  get messageHtml(): string {
    if (!this.text) {
      return ''
    }

    return formatLinks(this.text)
      .trim()
      .replace(/[\r\n]/g, '\n')
      .replace(/\n/g, '<br />')
  }

  get isEmoji(): boolean {
    const regexEmoji = /^(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])$/gmi
    const regexLines = /[\r\n]+/gm

    return regexEmoji.test(this.messageFormatted) && !regexLines.test(this.messageFormatted)
  }
}

export class ChatNotificationMessage extends ChatMessage {
  get isCustomPush() {
    try {
      return JSON.parse(this.customData).action === PushActionType.CustomPush
    } catch (e: any) {
      return false
    }
  }

  get customPushTitle() {
    try {
      return JSON.parse(this.customData).title
    } catch (e: any) {
      return ''
    }
  }

  get customPushText() {
    try {
      return JSON.parse(this.customData).text
    } catch (e: any) {
      return ''
    }
  }

  get notificationText() {
    const { lang } = useLocale()

    if (!this.text) {
      return ''
    }
    const parsedData = parseFieldLocales(this.text)

    return getFieldLocale(parsedData || '', lang.value)
  }

  get notificationHtml() {
    return formatLinks(this.notificationText)
      .trim()
      .replace(/[\r\n]/g, '\n')
      .replace(/\n/g, '<br />')
  }

  get notificationRelativeTime() {
    const date = this.createdAt

    if (!date) {
      return undefined
    }

    if (dayjs().subtract(1, 'day') < dayjs(date)) {
      return dayjs(date).fromNow()
    } else {
      return dayjs(date).format('DD.MM.YYYY HH:mm')
    }
  }
}
