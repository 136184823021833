import { Type, Expose, Transform, Exclude } from 'class-transformer'
import { OptionInterface } from 'client-core/plugins/locale'
import dayjs from 'dayjs'
import { useEvents } from '/~/state/events'
import { User } from './user'
import { Ticket } from './ticket'
import { plainToClass } from 'client-core/plugins/helpers'
import { useLocale } from '/-/plugins/locale'
import marked from 'marked'
import { removeTags } from '/-/plugins/format'

export enum HomeworkStatus {
  Created = 1,
  Approved,
  Declined,
}

export class AcademyModuleLite {
  id!: number
  active = true

  @Expose({ name: 'is_open' })
  isOpen!: boolean

  @Expose({ name: 'short_description' })
  title!: string

  @Expose({ name: 'start_date' })
  startDate = ''

  get startDateFormatted() {
    const { event } = useEvents()
    const date = dayjs.utc(this.startDate).tz(event.value?.timezone || 'UTC').format('DD.MM.YYYY HH:mm')

    return this.startDate ? `${date} (${event.value?.timezone})` : false
  }

  get startDateHumanized() {
    if (!this.startDate) {
      return ''
    }

    const { event } = useEvents()
    const { lang } = useLocale()

    const date = dayjs.utc(this.startDate).tz(event.value?.timezone || 'UTC')

    return lang.value === 'ru' ? date.format('D MMMM, YYYY') : date.format('MMMM D, YYYY')
  }
}

export class AcademyModule extends AcademyModuleLite {
  @Expose({ name: 'end_date' })
  endDate = ''

  priority!: number

  @Type(() => AcademyLessonLite)
  lessons: AcademyLessonLite[] = []

  @Expose({ name: 'full_description' })
  fullDescription = ''

  @Expose({ name: 'block_homework' })
  blockHomework!: boolean

  get descriptionMarked(): string {
    return marked(removeTags(this.fullDescription || ''))
      .replace(/<a href=/g, '<a target="_blank" href=')
  }
}

export class AcademyMemberLite {
  uid!: string

  @Expose({ name: 'user_id' })
  userId!: number

  @Expose({ name: 'first_name' })
  firstName!: string

  @Expose({ name: 'last_name' })
  lastName!: string

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`
  }
}

export class AcademyChatLesson {
  id!: number
  name!: string
}

export class AcademyLessonLite {
  id!: number

  active = true

  @Expose({ name: 'module_id' })
  moduleId!: number

  @Expose({ name: 'short_description' })
  title!: string

  @Expose({ name: 'start_date' })
  startDate!: string

  @Expose({ name: 'is_open' })
  isOpen!: boolean

  @Exclude({ toPlainOnly: true })
  @Expose({ name: 'teachers_list' })
  @Transform(({ obj }) => (obj.teachers || []).map((teacher: any) => plainToClass(teacher, AcademyMemberLite)) || [], { toClassOnly: true })
  teachersList!: AcademyMemberLite[]

  @Expose({ name: 'teachers_options' })
  @Exclude({ toPlainOnly: true })
  @Transform(({ obj }) => (obj.teachers || []).map((teacher: any) => ({ value: teacher.uid, label: teacher.first_name + ' ' + teacher.last_name })), { toClassOnly: true })
  teachersOptions!: OptionInterface[]

  @Transform(({ obj }) => (obj.teachers || []).map((teacher: any) => teacher.uid), { toClassOnly: true })
  teachers!: string[]

  @Type(() => AcademyHomework)
  homework!: AcademyHomework[]

  @Expose({ name: 'has_homework' })
  hasHomework!: boolean

  @Expose({ name: 'blocked_homework' })
  isBlockingHomework!: boolean

  get myHomework() {
    return this.homework.length ? this.homework[0] : null
  }

  get startDateFormatted() {
    const { event } = useEvents()
    const date = dayjs.utc(this.startDate).tz(event.value?.timezone || 'UTC').format('DD.MM.YYYY HH:mm')

    return this.startDate ? `${date} (${event.value?.timezone})` : false
  }

  get startDateHumanized() {
    if (!this.startDate) {
      return ''
    }

    const { event } = useEvents()
    const { lang } = useLocale()

    const date = dayjs.utc(this.startDate).tz(event.value?.timezone || 'UTC')

    return lang.value === 'ru' ? date.format('D MMMM, YYYY') : date.format('MMMM D, YYYY')
  }
}

export class AcademyLesson extends AcademyLessonLite {
  @Expose({ name: 'event_id' })
  eventId!: number

  content!: string

  @Expose({ name: 'homework_description' })
  homeworkDescription!: string

  @Expose({ name: 'end_date' })
  endDate!: string

  priority!: number
}

export class AcademyHomework {
  id!: number

  @Expose({ name: 'lesson_id' })
  lessonId!: number

  @Expose({ name: 'member_id' })
  memberId!: string

  status!: HomeworkStatus

  @Type(() => AcademyMemberLite)
  student!: AcademyMemberLite

  @Type(() => AcademyLessonLite)
  lesson!: AcademyLessonLite

  get isDeclined() {
    return this.status === HomeworkStatus.Declined
  }

  get isApproved() {
    return this.status === HomeworkStatus.Approved
  }

  get isPending() {
    return this.status === HomeworkStatus.Created
  }
}

export class AcademyStudent {
  uid!: string

  @Expose({ name: 'first_name' })
  firstName!: string

  @Expose({ name: 'last_name' })
  lastName!: string

  status!: number // from user/member entity

  @Expose({ name: 'education_progress' })
  progress!: string

  @Expose({ name: 'important_education_progress' })
  requiredProgress!: string

  @Type(() => User)
  user!: User

  @Type(() => Ticket)
  ticket!: Ticket

  @Type(() => AcademyHomework)
  homeworks!: AcademyHomework[]

  @Expose({ name: 'created_at' })
  createdAt!: string

  get fullName() {
    return this.firstName + ' ' + this.lastName
  }

  get ticketName() {
    return this.ticket?.name || '—'
  }

  get createdDate() {
    const { event } = useEvents()
    // Europe/Moscow is current server tz
    const date = dayjs.tz(this.createdAt, 'Europe/Moscow').tz(event.value?.timezone || 'UTC').format('DD.MM.YYYY HH:mm')

    return date
  }
}
