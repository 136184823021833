export default {
  bronze_sponsor: 'Bronze Sponsor',
  silver_sponsor: 'Silver Sponsor',
  gold_sponsor: 'Gold Sponsor',
  platinum_sponsor: 'Platinum Sponsor',
  organizer: 'Organizer',
  general_sponsor: 'General Sponsor',
  diamond_sponsor: 'Diamond Sponsor',
  sponsor: 'Sponsor',
}
