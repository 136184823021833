export default {
  new_message: 'New Message From',
  new_meeting_request: 'New Meeting Request',
  from: 'From',
  date: 'Date',
  new_date: 'New Date',
  with: 'with',
  declined: 'declined',
  rescheduled: 'rescheduled',
  accepted_meeting: 'meeting accepted',
  vote_accepted: 'Vote accepted',
  vote_accepted_descripton: 'Your Vote for :game successfully accepted',
  info_updated_success: 'Information successfully updated',
  info_updated_error: 'Sorry, information wasn\'t updated',
  lecture_agree_error: 'Sorry, failed to join to the lecture',

  calendar_lock_success: 'Slot was successfully locked',
  calendar_lock_error: 'Sorry, slot wasn\'t locked',
  calendar_unlock_success: 'Slot was successfully unlocked',
  calendar_unlock_error: 'Sorry, slot wasn\'t unlocked',

  vacancies_respond_success: 'Your respond was successfully sended',
  vacancies_respond_error: 'Sorry, your respond wasn\'t sended',

  game_create_success: 'Game was successfully created',
  game_create_error: 'Sorry, your game hasn\'t been created',
  game_create_upload_media_error: 'The game was created, but there was an error loading the images',
  game_update_success: 'Game was successfully updated',
  game_update_error: 'Sorry, game wasn\'t updated',
  game_update_upload_media_error: 'The game was updated, but there was an error loading the images',
  game_delete_success: 'Game was successfully removed',
  game_delete_error: 'Sorry, game wasn\'t removed',

  meeting_request_success: 'Your request has been sent. Wait for the meeting confirmation from the user',
  meeting_request_error: 'Sorry, your meeting request hasn\'t been sent',
  meeting_reschedule_success: 'Meeting was successfully rescheduled',
  meeting_reschedule_error: 'Sorry, meeting wasn\'t rescheduled',
  meeting_accept_success: 'Meeting was successfully accepted',
  meeting_accept_error: 'Sorry, meeting wasn\'t accepted',
  meeting_decline_success: 'Meeting was successfully declined',
  meeting_decline_error: 'Sorry, meeting wasn\'t declined',
  meeting_request_datetime_error: 'Sorry, the date or time is incorrect. Your data may be outdated, try to reload the page',
  meeting_not_onsite_member: 'Sorry, this user doesn\'t participate on-site, but you can contact him in the chat.',

  chat_report_success: 'Report was successfully sent',
  chat_report_error: 'Sorry, report wasn\'t sent',
  chat_report_already_sent: 'Sorry, you\'ve already sent a report',
  chat_user_blocked_error: 'Sorry, you can\'t send messages because you were temporarily blocked due to suspicious activity',

  company_create_error: 'Sorry, failed to create new company',

  recording_start_error: 'Sorry, failed to start recording',
  recording_stop_error: 'Sorry, failed to stop recording',
  recording_stop_success: 'The record is saved! You can find it in the "Stream records" section of the admin panel.',

  id_copied_success: 'Id was successfully copied to clipboard',
  url_copied_success: 'The url was successfully copied to the clipboard',
}
