import { computed, reactive, toRef } from 'vue'
import { PasswordDataInterface, User } from '/~/models/user'
import { Member } from '/~/models/member'
import { Api } from '/-/plugins/api'
import { useEvents } from '/~/state/events'
import { plainToClass, classToPlain } from '/-/plugins/helpers'
import { useConfig } from '/~/plugins/config'
import * as Sentry from '@sentry/vue'
import { Ticket } from '/~/models/ticket'
import { useChatService } from '/~/state/chat'
import { useMembers } from '/~/state/members'
import { useTimezones } from '/~/state/timezones'
import { useError } from '/~/plugins/error'
import { useApplication } from './application'

const { eventId } = useEvents()
const { config } = useConfig()
const { resetMemberCache } = useMembers()
const { getBrowserTimezone, isTimezoneExists } = useTimezones()
const { processError } = useError()
const { isNative } = useApplication()

interface ProfileStateInterface {
  member?: Member
  ticket?: Ticket
  chatToken?: string | null
}

const initialState = {
  profile: undefined,
  member: undefined,
  ticket: undefined,
  language: undefined,
  chatToken: undefined,
}

const state: ProfileStateInterface = reactive({ ...initialState })

function setMember(profile: Member) {
  state.ticket = plainToClass(profile.ticket, Ticket)
  state.member = plainToClass(profile, Member)
  Sentry.setUser({
    id: profile?.user.id.toString(),
    email: profile?.user.email
  })
}

async function removeProfile() {
  const { resetChat } = useChatService()

  await resetChat()

  Object.assign(state, { ...initialState })

  Sentry.setUser(null)
}

let fetchMemberPromise: null | Promise<{ data: Member }> = null

async function fetchMember(): Promise<{ data: Member } | void> {
  // // TODO: weak check, find a way to improve
  if (state.member?.ticket?.eventId === eventId.value) {
    return
  }
  if (fetchMemberPromise) {
    return fetchMemberPromise
  }
  fetchMemberPromise = Api.fetch({
    url: `/${eventId.value}/members/me`,
  })

  const data = await fetchMemberPromise

  data && setMember(data.data)

  fetchMemberPromise = null
}

async function reloadMember(): Promise<void> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/me`,
  }) as { data: Member }

  setMember(data)
}

async function getProfileVideoToken(channel: string) {
  const { data } = await Api.fetch(`/video/token/${channel}`)

  return data
}

async function updateMember(userData: Partial<User>): Promise<void> {
  const { data } = await Api.fetch({
    url: `/${eventId.value}/members/me`,
    method: 'PATCH',
    body: classToPlain(userData, User)
  })

  setMember(data)
  resetMemberCache()
}

async function updateProfileTimezone(): Promise<void> {
  if (!state.member) {
    return
  }

  const userTimezone = state.member?.user?.timezone
  const browserTimezone = getBrowserTimezone()

  if (userTimezone !== browserTimezone) {
    const isBrowserTimezoneExists = await isTimezoneExists(browserTimezone)

    if (userTimezone && !isBrowserTimezoneExists) {
      return
    }

    try {
      const { data } = await Api.fetch({
        url: `/${eventId.value}/members/me`,
        method: 'PATCH',
        body: {
          timezone: isBrowserTimezoneExists ? browserTimezone : 'UTC'
        }
      })

      setMember(data)
    } catch (error) {
      processError(error, false)
    }
  }
}

async function uploadMedia(
  file: File,
  category?: 'image' | 'logo' | 'background'
) {
  const { data } = await Api.fetch({
    url: '/users/me/media',
    method: 'POST',
    formData: {
      category: category || 'logo',
      media: file
    }
  })

  return data
}

async function updateProfilePassword(passwordData: PasswordDataInterface): Promise<void> {
  await Api.fetch({
    url: '/users/me/change-password',
    method: 'POST',
    body: classToPlain(passwordData)
  })
}

// must be deprecated
// async function updateProfileTicket() {
//   const { data } = await Api.fetch(`/${eventId.value}/members/me/upgrade-url`)

//   return data.url
// }

export function useProfile() {
  return {
    ticket: toRef(state, 'ticket'),
    member: toRef(state, 'member'),
    upgradeTicketUrl: computed(() => `${isNative ? '' : 'https://wnhub.io'}/users/me/tickets/${state.ticket?.id}`),
    profile: computed(() => state.member?.user),
    isChatEnabled: computed(() => config.value?.menu?.routes?.find(({ pageId }) => pageId === 'chats') && config.value?.chatPrivate?.active),
    fetchMember,
    reloadMember,
    updateMember,
    updateProfilePassword,
    removeProfile,
    getProfileVideoToken,
    setMember,
    uploadMedia,
    updateProfileTimezone
  }
}
