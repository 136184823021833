export const getGridColsClass = (
  colsNumberOnDesktop: number,
  colsNumberOnMobile?: number
) => {
  let className = ''

  const colsNumberOnMobileValue =
    colsNumberOnMobile || (colsNumberOnDesktop === 1 ? 1 : 2)

  switch (colsNumberOnMobileValue) {
  case 1:
    className += 'grid-cols-1 '
    break
  case 2:
    className += 'grid-cols-2 '
    break
  case 3:
    className += 'grid-cols-3 '
    break
  case 4:
    className += 'grid-cols-4 '
    break
  case 5:
    className += 'grid-cols-5 '
    break
  case 6:
    className += 'grid-cols-6 '
    break
  }

  switch (colsNumberOnDesktop) {
  case 1:
    className += 'md:grid-cols-1'
    break
  case 2:
    className += 'md:grid-cols-2'
    break
  case 3:
    className += 'md:grid-cols-3'
    break
  case 4:
    className += 'md:grid-cols-4'
    break
  case 5:
    className += 'md:grid-cols-5'
    break
  case 6:
    className += 'md:grid-cols-6'
    break
  }

  return className
}

export function getSlideWidth(cols: number, margin: number) {
  const gap = 40

  return `calc(${100 / cols}% - ${(gap / cols + margin).toFixed()}px)`
}
