export default {
  up: 'Move Up',
  down: 'Move Down',
  remove: 'Remove',
  edit_block: 'Edit block',
  save: 'Save',
  cancel: 'Cancel',
  open_block_menu: 'Add a new block',
  open_menu: 'Click on the block in the menu and it will appear at the bottom of the page',
  blocks: 'Blocks',
  close_menu: 'Close',
  apply: 'Apply',
  have_unsaved_data: 'You have unsaved changes!',
  reset: 'Discard',
  edit: 'Edit',
  close: 'Close',
  block_confirm_delete_title: 'Yes',
  block_confirm_delete_text: 'Are you sure you want to delete the block?',
  dublicate: 'Duplicate',
  hide: 'Hide',
  show: 'Show'
}
