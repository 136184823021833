export default {
  new_message: 'Новое сообщение от',
  new_meeting_request: 'Новое приглашение на встречу',
  meeting_declined: 'Встреча отменена',
  from: 'от',
  date: 'Дата',
  new_date: 'Новая дата',
  with: 'с',
  declined: 'отменена',
  rescheduled: 'перенесена',
  accepted_meeting: 'подтвердил(а) встречу',
  vote_accepted: 'Голос принят',
  vote_accepted_descripton: 'Ваш голос за :game успешно принят',
  info_updated_success: 'Информация была успешно обновлена',
  info_updated_error: 'Извините, не удалось обновить информацию',
  lecture_agree_error: 'Извините, не удалось присоединиться к лекции',

  calendar_lock_success: 'Слот успешно заблокирован',
  calendar_lock_error: 'Извините, не удалось заблокировать слот',
  calendar_unlock_success: 'Слот успешно разблокирован',
  calendar_unlock_error: 'Извините, не удалось разблокировать слот',

  vacancies_respond_success: 'Вы успешно откликнулись на вакансию',
  vacancies_respond_error: 'Извините, не удалось откликнуться на вакансию',

  game_create_success: 'Новая игра успешно добавлена',
  game_create_error: 'Извините, не удалось добавить игру',
  game_create_upload_media_error: 'Игра была создана, но при загрузке изображений произошла ошибка',
  game_update_success: 'Игра успешно обновлена',
  game_update_error: 'Извините, не удалось обновить игру',
  game_update_upload_media_error: 'Игра была обновлена, но при загрузке изображений произошла ошибка',
  game_delete_success: 'Игра успешно удалена',
  game_delete_error: 'Извините, не удалось удалить игру',

  meeting_request_success: 'Ваш запрос был успешно отправлен. Ожидайте подтверждения встречи от участника',
  meeting_request_error: 'Извините, не удалось отправить запрос на встречу',
  meeting_reschedule_success: 'Встреча успешно перенесена',
  meeting_reschedule_error: 'Извините, не удалось перенести встречу',
  meeting_accept_success: 'Встреча успешно подтверждена',
  meeting_accept_error: 'Извините, не удалось подтвердить встречу',
  meeting_decline_success: 'Встреча успешно отменена',
  meeting_decline_error: 'Извините, не удалось отменить встречу',
  meeting_request_datetime_error: 'Извините, некорректное дата или время. Ваши данные могли устареть, попробуйте перезагрузить страницу',
  meeting_not_onsite_member: 'Извините, этот пользователь участвует только онлайн, но вы можете связаться с ним в чате.',

  chat_report_success: 'Жалоба была успешно отправлена',
  chat_report_error: 'Извините, не удалось отправить жалобу',
  chat_report_already_sent: 'Извините, вы уже отправляли жалобу на этого пользователя',
  chat_user_blocked_error: 'Извините, вы не можете отправлять сообщения, так как были временно заблокированы из-за подозрительной активности',

  company_create_error: 'Извините, не удалось создать новую компанию',

  recording_start_error: 'Извините, не удалось начать запись',
  recording_stop_error: 'Извините, не удалось остановить запись',
  recording_stop_success: 'Запись сохранена! Вы сможете найти ее в разделе "Записи стримов" в панели администратора.',

  id_copied_success: 'Id был успешно скопирован в буфер обмена',
  url_copied_success: 'Url был успешно скопирован в буфер обмена',
}
