import { markRaw } from 'vue'
import VideoView from './VideoView.vue'
import { DEFAULT_COLOR_GRAY, DEFAULT_MARKDOWN_TEXT } from '../constants'
import schema from './schema'
import uiSchema from './ui-schema'

export default {
  id: 4,
  viewComponent: markRaw(VideoView),
  editorSchema: schema,
  editorUISchema: uiSchema,
  editorData: {
    title: 'Video',
    description: DEFAULT_MARKDOWN_TEXT,
    textColor: DEFAULT_COLOR_GRAY,
    source: 'youtube',
  },
}
